<template>
    <v-card class="mb-5">
      <v-card-title class="d-flex align-center">
        Client Contact Information
      </v-card-title>
  
      <v-card-text>
        <!-- Phone Numbers Section -->
        <v-row>
          <v-col cols="12">
            <v-card outlined>
              <v-card-title class="subtitle-1 d-flex justify-space-between">
                Phone Numbers
                <v-btn
                  small
                  color="primary"
                  @click="addPhone"
                >
                  Add Phone
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-row
                  v-for="(phone, index) in contactData.phones"
                  :key="index"
                  align="center"
                >
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="phone.number"
                      label="Phone number"
                      dense
                      :rules="[v => !v || phoneNumberRule(v) || 'Invalid phone number']"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      v-model="phone.type"
                      :items="phoneTypes"
                      label="Type"
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-checkbox
                      v-model="phone.preferred"
                      label="Preferred"
                      dense
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="2" class="d-flex justify-end">
                    <v-btn
                      small
                      icon
                      color="error"
                      @click="removePhone(index)"
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
  
        <!-- Email Addresses Section -->
        <v-row class="mt-4">
          <v-col cols="12">
            <v-card outlined>
              <v-card-title class="subtitle-1 d-flex justify-space-between">
                Email Addresses
                <v-btn
                  small
                  color="primary"
                  @click="addEmail"
                >
                  Add Email
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-row
                  v-for="(email, index) in contactData.emails"
                  :key="index"
                  align="center"
                >
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="email.address"
                      label="Email address"
                      dense
                      :rules="[v => !v || emailRule(v) || 'Invalid email address']"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-checkbox
                      v-model="email.preferred"
                      label="Preferred"
                      dense
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="2" class="d-flex justify-end">
                    <v-btn
                      small
                      icon
                      color="error"
                      @click="removeEmail(index)"
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
  
        <!-- Physical Address Section -->
        <v-row class="mt-4">
          <v-col cols="12">
            <v-card outlined>
              <v-card-title class="subtitle-1">
                Physical Address
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="contactData.physicalAddress.street1"
                      label="Street address"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="contactData.physicalAddress.street2"
                      label="Apartment, suite, etc. (optional)"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="contactData.physicalAddress.city"
                      label="City"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      v-model="contactData.physicalAddress.state"
                      :items="stateOptions"
                      label="State"
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="contactData.physicalAddress.zip"
                      label="ZIP code"
                      dense
                      :rules="[v => !v || zipCodeRule(v) || 'Invalid ZIP code']"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
  
        <!-- Mailing Address Section -->
        <v-row class="mt-4">
          <v-col cols="12">
            <v-card outlined>
              <v-card-title class="subtitle-1 d-flex align-center">
                Mailing Address
                <v-checkbox
                  v-model="sameAsPhysical"
                  label="Same as physical address"
                  class="ml-4"
                  dense
                ></v-checkbox>
              </v-card-title>
              <v-card-text>
                <v-row v-if="!sameAsPhysical">
                  <v-col cols="12">
                    <v-text-field
                      v-model="contactData.mailingAddress.street1"
                      label="Street address"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="contactData.mailingAddress.street2"
                      label="Apartment, suite, etc. (optional)"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="contactData.mailingAddress.city"
                      label="City"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      v-model="contactData.mailingAddress.state"
                      :items="stateOptions"
                      label="State"
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="contactData.mailingAddress.zip"
                      label="ZIP code"
                      dense
                      :rules="[v => !v || zipCodeRule(v) || 'Invalid ZIP code']"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
  
      <!-- Action Buttons -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="saveContactInfo"
        >
          Save Changes
        </v-btn>
      </v-card-actions>
    </v-card>
  </template>
  
  # Script
  <script>
  export default {
    name: 'ClientContactInfo',
  
    data() {
      return {
        sameAsPhysical: false,
        contactData: {
          phones: [
            {
              number: '',
              type: 'mobile',
              preferred: true
            }
          ],
          emails: [
            {
              address: '',
              preferred: true
            }
          ],
          physicalAddress: {
            street1: '',
            street2: '',
            city: '',
            state: '',
            zip: ''
          },
          mailingAddress: {
            street1: '',
            street2: '',
            city: '',
            state: '',
            zip: ''
          }
        },
  
        // Options for dropdowns
        phoneTypes: [
          { text: 'Mobile', value: 'mobile' },
          { text: 'Home', value: 'home' },
          { text: 'Work', value: 'work' },
          { text: 'Other', value: 'other' }
        ],
        stateOptions: [
          { text: 'Washington', value: 'WA' },
          { text: 'Oregon', value: 'OR' },
          { text: 'Idaho', value: 'ID' },
          // Add more states as needed
        ]
      }
    },
  
    watch: {
      sameAsPhysical(newVal) {
        if (newVal) {
          this.contactData.mailingAddress = { ...this.contactData.physicalAddress };
        }
      }
    },
  
    methods: {
      addPhone() {
        this.contactData.phones.push({
          number: '',
          type: 'mobile',
          preferred: false
        });
      },
  
      removePhone(index) {
        this.contactData.phones.splice(index, 1);
      },
  
      addEmail() {
        this.contactData.emails.push({
          address: '',
          preferred: false
        });
      },
  
      removeEmail(index) {
        this.contactData.emails.splice(index, 1);
      },
  
      phoneNumberRule(v) {
        const phonePattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        return phonePattern.test(v);
      },
  
      emailRule(v) {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(v);
      },
  
      zipCodeRule(v) {
        const zipPattern = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
        return zipPattern.test(v);
      },
  
      saveContactInfo() {
        // If mailing address is same as physical, update it before saving
        if (this.sameAsPhysical) {
          this.contactData.mailingAddress = { ...this.contactData.physicalAddress };
        }
        
        // Emit save event with current contact data
        this.$emit('save-contact-info', this.contactData);
      }
    }
  }
  </script>
<template>
    
    <v-card class="mb-5">
      <v-card-title class="d-flex align-center">
        Care Planning
      
      </v-card-title>
  
      <v-card-text>
        <!-- Services & Assistance Section -->
        <v-row>
          <v-col cols="12">
            <v-card outlined>
              <v-card-title class="subtitle-1">
                Services & Assistance Needed
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-checkbox
                      v-model="careData.services.housing"
                      label="Housing assistance"
                      dense
                    ></v-checkbox>
                    <v-checkbox
                      v-model="careData.services.employment"
                      label="Employment support"
                      dense
                    ></v-checkbox>
                    <v-checkbox
                      v-model="careData.services.education"
                      label="Education resources"
                      dense
                    ></v-checkbox>
                    <v-checkbox
                      v-model="careData.services.mentalHealth"
                      label="Mental health services"
                      dense
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-checkbox
                      v-model="careData.services.substanceUse"
                      label="Substance use treatment"
                      dense
                    ></v-checkbox>
                    <v-checkbox
                      v-model="careData.services.familySupport"
                      label="Family support services"
                      dense
                    ></v-checkbox>
                    <v-checkbox
                      v-model="careData.services.transportation"
                      label="Transportation assistance"
                      dense
                    ></v-checkbox>
                    <v-checkbox
                      v-model="careData.services.benefits"
                      label="Benefits application help"
                      dense
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
  
        <!-- Current Status Section -->
        <v-row class="mt-4">
          <v-col cols="12">
            <v-card outlined>
              <v-card-title class="subtitle-1">
                Current Status
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="6">
                    <!-- Custody Status -->
                    <v-select
                      v-model="careData.status.custody"
                      :items="custodyOptions"
                      label="Custody status"
                      dense
                    ></v-select>
                    
                    <!-- Housing Status -->
                    <v-select
                      v-model="careData.status.housing"
                      :items="housingOptions"
                      label="Housing status"
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <!-- Employment Status -->
                    <v-select
                      v-model="careData.status.employment"
                      :items="employmentOptions"
                      label="Employment status"
                      dense
                    ></v-select>
  
                    <!-- Education Status -->
                    <v-select
                      v-model="careData.status.education"
                      :items="educationOptions"
                      label="Education status"
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
  
        <!-- Progress Tracking Section -->
        <v-row class="mt-4">
          <v-col cols="12">
            <v-card outlined>
              <v-card-title class="subtitle-1 d-flex justify-space-between">
                Progress Tracking
                <v-btn
                  small
                  color="primary"
                  @click="addGoal"
                >
                  Add Goal
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-row
                  v-for="(goal, index) in careData.goals"
                  :key="index"
                  class="mb-2"
                >
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="goal.description"
                      label="Goal description"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      v-model="goal.status"
                      :items="goalStatusOptions"
                      label="Status"
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="goal.completionDate"
                      label="Completion date"
                      type="date"
                      dense
                      :disabled="goal.status !== 'completed'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2" class="d-flex align-center">
                    <v-btn
                      small
                      icon
                      color="error"
                      @click="removeGoal(index)"
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
  
        <!-- Case Closure Section -->
        <v-row class="mt-4">
          <v-col cols="12">
            <v-card outlined>
              <v-card-title class="subtitle-1">
                Case Closure Status
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="careData.closure.status"
                      :items="closureStatusOptions"
                      label="Case status"
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" v-if="careData.closure.status === 'closed'">
                    <v-textarea
                      v-model="careData.closure.summary"
                      label="Closure summary"
                      rows="3"
                      counter="1000"
                      dense
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
  
      <!-- Action Buttons -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="saveCareData"
        >
          Save Changes
        </v-btn>
      </v-card-actions>
    </v-card>
  </template>
  
  # Script
  <script>
  export default {
    name: 'CarePlanning',
  
    data() {
      return {
        careData: {
          services: {
            housing: false,
            employment: false,
            education: false,
            mentalHealth: false,
            substanceUse: false,
            familySupport: false,
            transportation: false,
            benefits: false
          },
          status: {
            custody: '',
            housing: '',
            employment: '',
            education: ''
          },
          goals: [],
          closure: {
            status: 'active',
            summary: ''
          }
        },
  
        // Status options
        custodyOptions: [
          { text: 'In custody', value: 'in_custody' },
          { text: 'Released', value: 'released' },
          { text: 'Pending hearing', value: 'pending' }
        ],
        housingOptions: [
          { text: 'Stable housing', value: 'stable' },
          { text: 'Temporary housing', value: 'temporary' },
          { text: 'Homeless', value: 'homeless' },
          { text: 'At risk of homelessness', value: 'at_risk' }
        ],
        employmentOptions: [
          { text: 'Full-time', value: 'full_time' },
          { text: 'Part-time', value: 'part_time' },
          { text: 'Unemployed', value: 'unemployed' },
          { text: 'Looking for work', value: 'seeking' }
        ],
        educationOptions: [
          { text: 'Less than high school', value: 'less_than_hs' },
          { text: 'High school/GED', value: 'hs_ged' },
          { text: 'Some college', value: 'some_college' },
          { text: 'College degree', value: 'college' }
        ],
        goalStatusOptions: [
          { text: 'Not started', value: 'not_started' },
          { text: 'In progress', value: 'in_progress' },
          { text: 'Completed', value: 'completed' },
          { text: 'Cancelled', value: 'cancelled' }
        ],
        closureStatusOptions: [
          { text: 'Active', value: 'active' },
          { text: 'Pending closure', value: 'pending' },
          { text: 'Closed', value: 'closed' }
        ]
      }
    },
  
    methods: {
      addGoal() {
        this.careData.goals.push({
          description: '',
          status: 'not_started',
          completionDate: ''
        });
      },
  
      removeGoal(index) {
        this.careData.goals.splice(index, 1);
      },
  
      saveCareData() {
        // Emit save event with current care data
        this.$emit('save-care-data', this.careData);
      }
    }
  }
  </script>
<template>
  <div class="home">
    <Opt
      @finish='regPhone'
      :dialog2='dialog2'
      :loginstate='false'
      :message='regmessage'
    />

    <v-dialog
      :persistent='true'
      overlay-opacity='100'
      v-model="dialog"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Register Phone Number for 2FA
        </v-card-title>

        <v-card-text class='mt-5'>
          Please Enter a phone number for Multi-Factor Auth
          <v-text-field
            prepend-icon="phone"
            label="Enter without any dashes ex 2061231234"
            type="number"
            v-model="phoneNumber"
          ></v-text-field>
          <span style='color:red'>{{regmessage}}</span>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading='loading'
            text
            @click="savePhone"
          >
            Register
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-row class="ma-2">
      <v-col
        v-for="(item, index) in dashboardItems"
        :key="index"
        cols="12"
        sm="6"
        md="4"
      >
        <v-card
          :color="item.color"
          dark
          hover
          :to="item.to"
        >
          <v-card-text>
            <v-row align="center" justify="space-between">
              <v-col class="text-left">
                <div class="text-h5 font-weight-bold">{{ item.title }}</div>
                <div class="text-h3 font-weight-bold mt-2">
                  {{ formatNumber(item.count) }}
                </div>
              </v-col>
              <v-col cols="auto">
                <v-icon size="64">{{ item.icon }}</v-icon>
              </v-col>
            </v-row>
          </v-card-text>
        
        </v-card>
      </v-col>
    </v-row>




  

  </div>
</template>

<script>
import { getCases } from "../dataservices/caseDataService";
// @ is an alias to /src
import { mapFields } from "vuex-map-fields";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { updatePhoneNumber } from "../dataservices/adminDataService";
import Opt from "../components/Opt";
export default {
  name: "Home",
  async mounted() {
    this.cases = await getCases();
    this.opencases = this.cases.filter(e => e.Status !== "Closed").length;
    this.closecases = this.cases.filter(e => e.Status === "Closed").length;
    if (this.user.data.multiFactor.enrolledFactors.length === 0) {
      this.dialog = true;
    }
  },
  methods: {

    formatNumber(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },


    async regPhone(smscode) {
      const cred = firebase.auth.PhoneAuthProvider.credential(
        this.verificationId,
        smscode
      );
      const multiFactorAssertion =
        firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
      await this.user.data.multiFactor.enroll(
        multiFactorAssertion,
        "phone number"
      );
      alert("enrolled in MFA");
      location.reload();
    },
    async savePhone() {
      this.loading = true;
      try {
        this.regmessage = null;
        await updatePhoneNumber(this.phoneNumber);
        await this.user.data.multiFactor
          .getSession()
          .then((multiFactorSession) => {
            let phoneInfoOptions = {
              phoneNumber: "+1" + this.phoneNumber,
              session: multiFactorSession,
            };

            this.phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
            // Send SMS verification code.
            return this.phoneAuthProvider
              .verifyPhoneNumber(phoneInfoOptions, window.recaptchaVerifier)
              .then((verificationId) => {
                this.verificationId = verificationId;
              });
          });
        this.loading = false;
        this.dialog = false;
        this.dialog2 = true;
      } catch (e) {
        console.log(e);
        this.loading = false;
        this.regmessage =
          "Invalid Format : Please Check your phone number you entered";
      }
    },
  },
  data() {
    return {
      dashboardItems: [
      {
          title: 'All Open Cases',
          count: 0,
          color: 'blue',
          icon: 'mdi-account',
          to: '/cases'
        },
        {
          title: 'All Closed Cases',
          count: 0,
          color: 'deep-purple',
          icon: 'mdi-file-document-outline',
           to: '/cases'
        },
        // {
        //   title: '# of Transfers',
        //   count: 0,
        //   color: 'teal',
        //   icon: 'mdi-calculator',
        //    to: '#'
        // },
      ],
      closecases : 0,
      opencases  : 0,
      selected: null,
      dashboardData: {
        totalContractors: 0,
      },
      dialog2: false,
      phoneNumber: null,
      regmessage: null,
      loading: false,
      dialog: false,
      phoneAuthProvider: null,
      verificationId: null,
    };
  },
  components: {
    Opt,
  },
  computed: {
    ...mapFields(["user","role"]),
  },
};
</script>

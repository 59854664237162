import axios from 'axios';

const getFormatedCases = async () => {
     const result = await axios.get("https://dp-02-db2a5.wl.r.appspot.com/internal/getFormattedCases")
     return result.data;
}


const createCase = async (data) => {
     const result = await axios.post("https://dp-02-db2a5.wl.r.appspot.com/internal/createCase",data)
     return result.data;
}



const removeCause = async (data) => {
     const result = await axios.post("https://dp-02-db2a5.wl.r.appspot.com/internal/removeCause/" + data.CaseNumberID)
     return result.data;
}

const getCaseById = async (id) => {
     const result = await axios.get("https://dp-02-db2a5.wl.r.appspot.com/internal/getFormattedCase/"+ id)
     return result.data;
}

const getCases = async() => {
     const result = await axios.get("https://dp-02-db2a5.wl.r.appspot.com/internal/getCases");
     return result.data;
}

const updateCase = async (id,data) => {
     const result = await axios.post("https://dp-02-db2a5.wl.r.appspot.com/internal/updateCase/"+ id,data)
     return result.data;
}

const transferAction = async (fromid, from, to) => {
     const result = await axios.post("https://dp-02-db2a5.wl.r.appspot.com/internal/transferCase/", {
          fromid : fromid,
          from : from,
          to : to
     })
     return result.data;
}

export {getFormatedCases,createCase, getCases, getCaseById,removeCause,updateCase, transferAction}
<template>
  <v-card class="mb-5">
    <v-card-title class="d-flex align-center">
      Collateral Contacts
   
    </v-card-title>

    <v-card-text>
      <!-- Family Members Section -->
      <v-row>
        <v-col cols="12">
          <v-card outlined>
            <v-card-title class="subtitle-1 d-flex justify-space-between">
              Family Members
              <v-btn
                small
                color="primary"
                @click="addContact('family')"
              >
                Add Family Member
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row
                v-for="(contact, index) in collateralData.familyMembers"
                :key="'family-' + index"
                align="center"
              >
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="contact.name"
                    label="Name"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-select
                    v-model="contact.relationship"
                    :items="familyRelationships"
                    label="Relationship"
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="contact.phone"
                    label="Phone"
                    dense
                    :rules="[v => !v || phoneNumberRule(v) || 'Invalid phone']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="contact.email"
                    label="Email"
                    dense
                    :rules="[v => !v || emailRule(v) || 'Invalid email']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="1" class="d-flex justify-end">
                  <v-btn
                    small
                    icon
                    color="error"
                    @click="removeContact('family', index)"
                  >
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Support People Section -->
      <v-row class="mt-4">
        <v-col cols="12">
          <v-card outlined>
            <v-card-title class="subtitle-1 d-flex justify-space-between">
              Support People
              <v-btn
                small
                color="primary"
                @click="addContact('support')"
              >
                Add Support Person
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row
                v-for="(contact, index) in collateralData.supportPeople"
                :key="'support-' + index"
                align="center"
              >
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="contact.name"
                    label="Name"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-select
                    v-model="contact.type"
                    :items="supportTypes"
                    label="Support type"
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="contact.phone"
                    label="Phone"
                    dense
                    :rules="[v => !v || phoneNumberRule(v) || 'Invalid phone']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="contact.email"
                    label="Email"
                    dense
                    :rules="[v => !v || emailRule(v) || 'Invalid email']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="1" class="d-flex justify-end">
                  <v-btn
                    small
                    icon
                    color="error"
                    @click="removeContact('support', index)"
                  >
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Service Providers Section -->
      <v-row class="mt-4">
        <v-col cols="12">
          <v-card outlined>
            <v-card-title class="subtitle-1 d-flex justify-space-between">
              Service Providers
              <v-btn
                small
                color="primary"
                @click="addContact('provider')"
              >
                Add Service Provider
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row
                v-for="(provider, index) in collateralData.serviceProviders"
                :key="'provider-' + index"
                align="center"
              >
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="provider.name"
                    label="Provider name"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-select
                    v-model="provider.type"
                    :items="providerTypes"
                    label="Service type"
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    v-model="provider.phone"
                    label="Phone"
                    dense
                    :rules="[v => !v || phoneNumberRule(v) || 'Invalid phone']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    v-model="provider.email"
                    label="Email"
                    dense
                    :rules="[v => !v || emailRule(v) || 'Invalid email']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    v-model="provider.organization"
                    label="Organization"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="1" class="d-flex justify-end">
                  <v-btn
                    small
                    icon
                    color="error"
                    @click="removeContact('provider', index)"
                  >
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- Action Buttons -->
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        @click="saveCollateralContacts"
      >
        Save Changes
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

# Script
<script>
export default {
  name: 'CollateralContacts',

  data() {
    return {
      collateralData: {
        familyMembers: [],
        supportPeople: [],
        serviceProviders: []
      },

      // Options for dropdowns
      familyRelationships: [
        { text: 'Parent', value: 'parent' },
        { text: 'Spouse', value: 'spouse' },
        { text: 'Sibling', value: 'sibling' },
        { text: 'Child', value: 'child' },
        { text: 'Other Family', value: 'other_family' }
      ],

      supportTypes: [
        { text: 'Friend', value: 'friend' },
        { text: 'Mentor', value: 'mentor' },
        { text: 'Advocate', value: 'advocate' },
        { text: 'Religious/Spiritual', value: 'religious' },
        { text: 'Other Support', value: 'other_support' }
      ],

      providerTypes: [
        { text: 'Mental Health', value: 'mental_health' },
        { text: 'Medical', value: 'medical' },
        { text: 'Legal', value: 'legal' },
        { text: 'Housing', value: 'housing' },
        { text: 'Education', value: 'education' },
        { text: 'Employment', value: 'employment' },
        { text: 'Substance Treatment', value: 'substance' },
        { text: 'Other Service', value: 'other_service' }
      ]
    }
  },

  methods: {
    addContact(type) {
      const newContact = {
        name: '',
        phone: '',
        email: ''
      };

      switch (type) {
        case 'family':
          this.collateralData.familyMembers.push({
            ...newContact,
            relationship: ''
          });
          break;
        case 'support':
          this.collateralData.supportPeople.push({
            ...newContact,
            type: ''
          });
          break;
        case 'provider':
          this.collateralData.serviceProviders.push({
            ...newContact,
            type: '',
            organization: ''
          });
          break;
      }
    },

    removeContact(type, index) {
      switch (type) {
        case 'family':
          this.collateralData.familyMembers.splice(index, 1);
          break;
        case 'support':
          this.collateralData.supportPeople.splice(index, 1);
          break;
        case 'provider':
          this.collateralData.serviceProviders.splice(index, 1);
          break;
      }
    },

    phoneNumberRule(v) {
      const phonePattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      return phonePattern.test(v);
    },

    emailRule(v) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(v);
    },

    saveCollateralContacts() {
      this.$emit('save-collateral', this.collateralData);
    }
  }
}
</script>
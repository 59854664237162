<template>
    <v-card class="mb-5">
        <v-card-title class="d-flex align-center">
            Client contact log

      
      </v-card-title>
      <v-card-text>
        <!-- Date and Duration Row -->
        <v-row>
          <v-col cols="12" lg="6">
            <v-text-field
              v-model="contactData.date"
              label="Date of contact"
              type="date"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6">
            <v-select
              v-model="contactData.duration"
              :items="durationOptions"
              label="Length of contact"
              dense
            ></v-select>
          </v-col>
        </v-row>
  
        <!-- Contact Type Row -->
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="contactData.type"
              :items="contactTypeOptions"
              label="Type of contact"
              dense
            ></v-select>
          </v-col>
        </v-row>
  
       
  
        <!-- Notes Section -->
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="contactData.notes"
              label="Notes about contact "
              counter="500"
              :rules="[v => !v || v.length <= 500 || 'Max 500 characters']"
              dense
              rows="3"
            ></v-textarea>
          </v-col>
        </v-row>
  
      </v-card-text>
    </v-card>
  </template>
  

  <script>
  export default {
    name: 'ContactTracking',
    
    data() {
      return {
        contactData: {
          date: '',
          duration: '0',
          type: '',
          notes: ''
        },
        
        // Duration options in minutes
        durationOptions: [
          { text: '0 minutes', value: '0' },
          { text: '15 minutes', value: '15' },
          { text: '30 minutes', value: '30' },
          { text: '45 minutes', value: '45' },
          { text: '60 minutes', value: '60' }
        ],
  
        // Contact type options
        contactTypeOptions: [
          { text: 'Phone Call', value: 'phone' },
          { text: 'Email', value: 'email' },
          { text: 'In-Person Meeting', value: 'meeting' },
          { text: 'Video Call', value: 'video' },
          { text: 'Text Message', value: 'text' },
          { text: 'Letter', value: 'letter' }
        ]
      }
    },
  
    computed: {
      // This can be used to conditionally show the restricted access warning
      // based on user role/permissions from your auth system
      showRestrictedWarning() {
        return true; // You can modify this based on your authentication logic
      }
    },
  
    methods: {
      // Method to emit contact data to parent component
      saveContact() {
        this.$emit('save-contact', this.contactData);
      },
  
      // Method to reset form
      resetForm() {
        this.contactData = {
          date: '',
          duration: '0',
          type: '',
          notes: ''
        };
      }
    }
  }
  </script>